<template>

    <div class="flex h-full overflow-hidden">
    
        <loader :loading="loading" />

        <div v-if="!selected && mediacards" class="w-3/5">

            <div v-if="lastest.length > 0" class="w-full h-full flex flex-col mr-8">

                <div class="h-8 box-title mb-4">
                    <i class="mdi mdi-star text-gold"></i>
                    <span class="ml-2">{{ lastest[0].date | moment('DD - MM - YYYY') }}</span>
                </div>

                <div class="box flex-1 min-h-0 rounded-lg p-2 flex flex-col shadow-md mb-2">

                    <div v-if="lastest[0].image" class="h-64 w-full rounded-lg bg-cover bg-center shadow-xl relative" :style="'background-image: url(' + imageRoute + lastest[0].image + ')'"></div>
                    
                    <div class="flex flex-col flex-1 min-h-0 p-2 font-thin overflow-hidden relative">

                        <i v-if="lastest[0].link && isValid(lastest[0].link)" class="mdi mdi-open-in-new text-font-gray cursor-pointer absolute right-0 top-0 mt-2 mr-2" @click="go(lastest[0].link)"></i>

                        <div class="h-auto flex flex-row justify-start items-center w-full">
                            <h4 class="text-2xl font-medium leading-normal truncate-2 capitalize">{{ lastest[0].title }}</h4>
                            <span class="text-lg text-font-gray ml-auto">{{lastest[0].date | moment('DD / MM / YYYY')}}</span>
                        </div>

                        <div class="mt-4 flex-1 min-h-0 text-lg text-font-gray leading-normal overflow-hidden" v-html="lastest[0].body"></div>
                       
                        <div v-if="lastest[0].file" class="h-auto mt-2 flex flex-row justify-end items-center cursor-pointer" @click="openFile(lastest[0].file, 'file')">
                            <i class="mdi text-white text-xl" :class="extension(lastest[0].file)"></i>
                        </div>

                    </div>

                </div>

            </div>

            <div v-else class="w-full flex flex-col mr-8">
                <div class="w-full h-full flex flex-col mr-8">
                    <div class="box-title mb-4">
                        <i class="mdi mdi-star text-gold"></i>
                        <span class="ml-2">
                            {{ mediacards[0].date | moment('DD - MM - YYYY') }}
                        </span>
                    </div>
                    <div class="box h-full p-0 flex flex-col shadow-md">
                        <div v-if="mediacards[0].image" class="h-72 w-full rounded-lg bg-cover bg-center shadow-xl relative"
                            :style="'background-image: url(' + imageRoute + mediacards[0].image + ')'"></div>
                        <div class="flex-1 p-2 font-thin overflow-hidden relative mt-2">
                            <i v-if="mediacards[0].link" class="mdi mdi-open-in-new text-font-gray cursor-pointer absolute right-0 top-0 mt-2 mr-2" @click="go(mediacards[0].link)"></i>
                            <div class="text-4xl font-medium leading-normal ml-4">{{ mediacards[0].title }}</div>
                            <div class="mt-6 flex-1 min-h-0 text-lg text-font-gray leading-normal ml-6 h-40 overflow-auto" v-html="mediacards[0].body"></div>
                            <div v-if="mediacards[0].file" class="h-8 flex flex-row justify-end items-center cursor-pointer" @click="openFile(mediacards[0].file, 'file')">
                                <i class="mdi text-white" :class="extension(mediacards[0].file)"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div v-else class="w-full">

            <div class="w-full h-full flex flex-col mr-8">

                <div class="h-8 box-title mb-4 flex">
                    <i class="mdi mdi-star text-gold"></i>
                    <span class="ml-2 mr-auto">{{ selected.date | moment('DD - MM - YYYY') }}</span>

                    <router-link class="h-full flex flex-row justify-center items-center" :to="{ name: 'Media', params: { id: null }}">
                        <i class="mdi mdi-chevron-left text-aux"></i>
                        <span class="text-sm text-aux ml-2">Go back</span>
                    </router-link>

                </div>

                <div class="box flex-1 min-h-0 rounded-lg p-2 flex flex-col shadow-md mb-2">

                    <div v-if="selected.image" class="h-64 w-full rounded-lg bg-cover bg-center shadow-xl relative" :style="'background-image: url(' + imageRoute + selected.image + ')'"></div>
                    
                    <div class="flex flex-col flex-1 min-h-0 p-2 font-thin overflow-hidden relative">

                        <i v-if="selected.link && isValid(selected.link)" class="mdi mdi-open-in-new text-font-gray cursor-pointer absolute right-0 top-0 mt-2 mr-2" @click="go(selected.link)"></i>

                        <div class="h-auto flex flex-row justify-start items-center w-full">
                            <h4 class="text-2xl font-medium leading-normal truncate-2 capitalize">{{ selected.title }}</h4>
                            <span class="text-lg text-font-gray ml-auto">{{selected.date | moment('DD / MM / YYYY')}}</span>
                        </div>

                        <div class="mt-4 flex-1 min-h-0 text-lg text-font-gray leading-normal overflow-hidden" v-html="selected.body"></div>
                       
                        <div v-if="selected.file" class="h-auto mt-2 flex flex-row justify-end items-center cursor-pointer" @click="openFile(selected.file, 'file')">
                            <i class="mdi text-white text-xl" :class="extension(selected.file)"></i>
                        </div>

                    </div>

                </div>

            </div>

        </div>

        <div v-if="!selected" class="w-2/5 flex flex-col ml-8 ">

            <div class="flex mb-2">
                <div class="ml-auto">
                    <div class="relative flex-none w-84">
                        <input type="text" placeholder="Search" v-model="searchInput"
                            class="w-full h-8 rounded bg-body flex flex-row justify-start
                            items-center px-2">
                    </div>
                </div>
            </div>

            <div class="flex-1 min-h-0 overflow-auto relative">

                <div class="h-auto mt-4 relative">
                    <div class="h-auto flex flex-row justify-between items-center">
                        <div class="box-title mb-4">More news</div>
                        <div class="h-8 w-8 cursor-pointer mr-12 flex flex-col justify-center
                            items-center" @click="openMoreNews = !openMoreNews">
                            <i class="mdi font-semibold text-xl" :class="{'mdi-plus': !openMoreNews, 'mdi-minus': openMoreNews}"></i>
                        </div>
                    </div>
                    <div class="overflow-hidden relative" :class="{'h-auto': openMoreNews, 'h-0': !openMoreNews}">
                        <div v-if="more_news.length > 0" class="h-full overflow-auto pr-12">
                            
                            <media-card v-for="(el, index) in search(more_news)" :key="index" :data="el" @select="select" :select="selected"/>

                        </div>
                        <div v-else class="h-20 w-full flex flex-col justify-center items-center">
                            <span class="text-sm text-font-gray">No data in this section</span>
                        </div>
                    </div>
                </div>

                <div class="h-auto mt-4">
                    <div class="h-auto flex flex-row justify-between items-center">
                        <div class="box-title mb-4">Competitors</div>
                        <div class="h-8 w-8 cursor-pointer mr-12 flex flex-col justify-center items-center" @click="openCompetence = !openCompetence">
                            <i class="mdi font-semibold text-xl" :class="{'mdi-plus': !openCompetence, 'mdi-minus': openCompetence}"></i>
                        </div>
                    </div>
                    <div class="overflow-hidden relative" :class="{'h-auto': openCompetence, 'h-0': !openCompetence}">
                        <div v-if="competence.length > 0" class="h-full overflow-auto pr-12">
                            <media-card v-for="(el, index) in search(competence)" :key="index" :data="el" @select="select" :select="selected"/>
                        </div>
                        <div v-else class="h-20 w-full flex flex-col justify-center items-center">
                            <span class="text-sm text-font-gray">No data in this section</span>
                        </div>
                    </div>
                </div>

                <div class="h-auto mt-4">
                    <div class="h-auto flex flex-row justify-between items-center">
                        <div class="box-title mb-4">Economy and Lifestyle</div>
                        <div class="h-8 w-8 cursor-pointer mr-12 flex flex-col justify-center items-center"
                            @click="openEconomyLifeStyle = !openEconomyLifeStyle">
                            <i class="mdi font-semibold text-xl"
                                :class="{'mdi-plus': !openEconomyLifeStyle, 'mdi-minus': openEconomyLifeStyle}"></i>
                        </div>
                    </div>
                    <div class="overflow-hidden relative" :class="{'h-auto': openEconomyLifeStyle, 'h-0': !openEconomyLifeStyle}">
                        <div v-if="economy_lifestyle.length > 0" class="h-full overflow-auto pr-12">
                            <media-card v-for="(el, index) in search(economy_lifestyle)" :key="index" :data="el" @select="select" :select="selected"/>
                        </div>
                        <div v-else class="h-20 w-full flex flex-col justify-center items-center">
                            <span class="text-sm text-font-gray">No data in this section</span>
                        </div>
                    </div>
                </div>

            </div>

            <div class="h-16 flex flex-row mt-2">

                <div class="h-full w-1/3 p-2">
                    <div @click="go(coverPage)" class="h-full cursor-pointer rounded-lg shadow bg-font-gray-15 flex flex-row
                        justify-between items-center px-2">
                        <span class="text-font-gray font-semibold text-lg">Portadas del día</span>
                        <i class="mdi mdi-open-in-new text-font-gray text-lg"></i>
                    </div>
                </div>
                <div class="h-full w-1/3 p-2">
                    <div @click="go(histClipping)" class="h-full cursor-pointer rounded-lg shadow bg-font-gray-15 flex
                        flex-row justify-between items-center px-2">
                        <span class="text-font-gray font-semibold text-lg">Hist. Clipping</span>
                        <i class="mdi mdi-open-in-new text-font-gray text-lg"></i>
                    </div>
                </div>
                <div class="h-full w-1/3 p-2">
                    <div @click="go(newsRelease)" class="h-full cursor-pointer rounded-lg shadow bg-font-gray-15 flex flex-row
                        justify-between items-center px-2">
                        <span class="text-font-gray font-semibold text-lg">Notas de prensa</span>
                        <i class="mdi mdi-open-in-new text-font-gray text-lg"></i>
                    </div>
                </div>

            </div>

        </div>

    </div>

</template>

<script>

const MediaCard = () => import('@/components/Globals/PersonalDashboard/MediaCard');
import { state } from '@/store'

export default {
    components: {
        MediaCard
    },
    data() {
        return {
            loading: false,
            mediacards: null,
            searchInput: '',
            more_news: [],
            lastest: [],
            competence: [],
            economy_lifestyle: [],
            pdfSelected: false,
            fileOpen: false,
            openMoreNews: true,
            openEconomyLifeStyle: true,
            openCompetence: true,
            selected: false,
            coverPage: false,
            newsRelease: false,
            histClipping: false,
            allMediaCards: false
        }
    },
    methods: {
        isValid(link){ 
            return this.$is_url(link) 
        },
        extension(file) {
            let result = ''
            if (file) {result += this.$iconByExtension(file) }
            return result
        },
        async load() {
            try {
                this.loading = true;

                if(!this.mediaId){

                    const { data } = await this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=Media&sorts[date]=DESC', {params: state.globalParams});
                    this.allMediaCards = data;
                    this.mediacards = data.filter((media) => media.active == 1);
                    this.selectMedia(this.mediaId);

                } else {

                    const { data } = await this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'get?resource=Media&id='+this.mediaId, {params: {...state.globalParams, active:0}});
                    this.selected = data;

                }

                
                const mediaLinks = await this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'media_links');

                this.coverPage = mediaLinks.data.cover_page;
                this.newsRelease = mediaLinks.data.press_releases;
                this.histClipping = mediaLinks.data.hist_clipping;



                const { more_news, competence, lastest, economy_lifestyle  }  = this.$mediaType(this.mediacards);
                this.more_news = more_news;
                this.competence = competence;
                this.lastest = lastest;
                this.economy_lifestyle = economy_lifestyle;

            } catch (error) {
            } finally {
                this.loading = false;
            }
            
        },
        search(data){ 
            return this.$search(data, this.searchInput) 
        },
        openFile(file, type){ 
            this.$openFile(file, type) 
        },
        go(link){ 
            window.open(link, '_blank') 
        },
        select(option){
            this.$router.push({ name: 'Media', params: { id: option.id } });
        },
        selectMedia(id) {

            if (id) {

                const media = this.allMediaCards.find(item => String(item.id) === String(id));
                this.selected = media;

            } else {

                this.selected = null;

            }
        }
    },
    computed: {
        imageRoute(){ 
            return this.$imageRoute 
        },
        params() {
            let params = state.globalParams
            if (this.$route.params.catId) {
                params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
            }
            return params
        },
        mediaId() {
            return this.$route.params.id;
        }
    },
    watch: {
        params() {
            this.load();
        },
        mediaId(id) {
            if (id) {
                this.selectMedia(id);
            }
        }
    },
    mounted() {
        this.load();

        setInterval(() => {
            this.load();
        }, state.refreshPersonalDashboard);
    }
}
</script>